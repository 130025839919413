import '../styles/globals.css';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { AuthProvider } from '@/lib/auth';
import Layout from '@/components/Layout';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
    getHeader?: string;
    isAdminOnly?: boolean;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const header = Component.getHeader ?? 'Virra';
    const isAdminOnly = Component.isAdminOnly ?? false;
    // const getLayout = Component.getLayout ?? ((page) => page);
    return (
        <AuthProvider>
            <Layout header={header} isAdminOnly={isAdminOnly}>
                {/* {getLayout(<Component {...pageProps} />)} */}
                <Component {...pageProps} />
            </Layout>
        </AuthProvider>
    );
}
