import { useState, useEffect, useContext, createContext } from 'react';
import {
    clientAuth,
    sendSignInLinkToEmail,
    signOut,
    isSignInWithEmailLink,
    signInWithEmailLink,
    getIdToken,
    getIdTokenResult,
    verifyBeforeUpdateEmail,
} from './firebase';
import { createUser } from './firestore-client';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import Router from 'next/router';
// import Router from 'next/router';
import type { AuthProps, UserAuthProps } from 'types/AuthProps';
import Loading from '@/components/Loading';
import Navigation from '@/components/Navigation';
import { UserCredential } from 'firebase/auth';

// PSEUDO DELETE
// page: /login
// user: submits email in form
// function: sendSignInLinkToEmail
// page: set emailForSignIn in localStorage
// user: clicks link in email to /auth
// page: isSignInWithEmailLink then signInWithEmailLink
// page: Set user in cookies (so it can be accessed by server)
// PSEUDO DELETE

export const loginPaths = ['/login', '/auth'];

const authContext = createContext({ user: undefined } as any);

export function AuthProvider({ children }: any) {
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = (): AuthProps => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState<UserAuthProps | null | false>(null);
    const [loading, setLoading] = useState(true);

    const handleUser = async (rawUser: any) => {
        if (rawUser) {
            const user = await formatUser(rawUser);

            const { token, ...userWithoutToken } = user;

            createUser(user.uid, userWithoutToken);

            // If admin require verification before creating user

            // createUser(user.email, userWithoutToken);
            const isVerified = clientAuth.currentUser?.emailVerified;
            // if (isVerified == false) {
            //   console.log('waiting for user to verify');
            //   Router.push('/signin?verify');
            // } else {
            //   console.log('user verified');

            setUser(user as UserAuthProps);
            Cookie.set('virra-auth', 'true', {
                expires: 1,
            });
            // console.log('handle user');

            setLoading(false);
            return user;
        } else {
            setUser(false);
            Cookie.remove('virra-auth');

            setLoading(false);
            return false;
        }
    };

    const updateEmail = async (newEmail: string) => {
        if (clientAuth.currentUser) {
            try {
                await verifyBeforeUpdateEmail(clientAuth.currentUser, newEmail);
            } catch (error: any) {
                console.log(error);
                return error;
            }
        }
    };

    const sendSignInLink = (email: string) => {
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            // https://superfan.virra.com/auth
            url:
                process.env.NODE_ENV === 'development'
                    ? 'http://localhost:3000/auth'
                    : String(process.env.NEXT_PUBLIC_DEPLOYED_DOMAIN + '/auth'),
            //   url: 'http://localhost:3000/auth',
            // https://app-virra-com.vercel.app/
            //   url: 'https://app.virra.com/auth',
            handleCodeInApp: true, // This must be true.
            // dynamicLinkDomain: 'example.page.link'
        };
        console.log('Sending sign in link');
        sendSignInLinkToEmail(clientAuth, email, actionCodeSettings)
            .then(() => {
                console.log('Worked?');
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                // ...
            })
            .catch((error: { code: any; message: any }) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(error);
                // ...
            });
    };

    const completeSignOnWebPage = (
        email?: string
    ): 'success' | 'confirmEmail' | undefined => {
        if (isSignInWithEmailLink(clientAuth, window.location.href)) {
            console.log('is sign in with email link');
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            console.log('running sign in');

            if (typeof email !== 'undefined') {
                console.log('state: email passed in function');
                signInWithEmailLink(clientAuth, email, window.location.href)
                    .then((result: UserCredential) => {
                        // Clear email from storage.
                        window.localStorage.removeItem('emailForSignIn');
                        console.log('sign in success');
                        console.log('resuser: ', result.user);
                        handleUser(result.user);
                        Router.push('/');
                        // You can access the new user via result.user
                        // Additional user info profile not available via:
                        // result.additionalUserInfo.profile == null
                        // You can check if the user is new or existing:
                        // result.additionalUserInfo.isNewUser
                    })
                    .catch((error: { code: string }) => {
                        console.log('really not fucking working');
                        if (error.code === 'auth/invalid-email') {
                            Router.push('/login');
                        }
                        console.log(error.code);
                        // Some error occurred, you can inspect the code: error.code
                        // Common errors could be invalid email and invalid or expired OTPs.
                    });
                return 'success';
            } else if (window.localStorage.getItem('emailForSignIn')) {
                const email = window.localStorage.getItem(
                    'emailForSignIn'
                ) as string;
                console.log('state: email passed in local storage');
                // The client SDK will parse the code from the link for you.
                console.log({
                    clientAuth,
                    email,
                    window: window.location.href,
                });
                signInWithEmailLink(clientAuth, email, window.location.href)
                    .then((result: { user: any }) => {
                        // Clear email from storage.
                        window.localStorage.removeItem('emailForSignIn');
                        console.log('sign in success');
                        console.log('resuser: ', result.user);
                        handleUser(result.user);
                        Router.push('/');
                        // You can access the new user via result.user
                        // Additional user info profile not available via:
                        // result.additionalUserInfo.profile == null
                        // You can check if the user is new or existing:
                        // result.additionalUserInfo.isNewUser
                    })
                    .catch((error: { code: string }) => {
                        console.log('really not working');
                        if (error.code === 'auth/invalid-email') {
                            Router.push('/login');
                        }
                        console.log(error.code);
                        // Some error occurred, you can inspect the code: error.code
                        // Common errors could be invalid email and invalid or expired OTPs.
                    });
                return 'success';
            } else {
                //
                console.log('state: email was not passed');
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                console.log('pls confirm');
                return 'confirmEmail';
                // email = window.prompt('Please provide your email for confirmation');
                //
            }
        }
    };

    const signout = () => {
        return signOut(clientAuth).then(() => {
            handleUser(false);
            Router.push('/login');
        });
    };

    useEffect(() => {
        const unsubscribe = clientAuth.onAuthStateChanged(handleUser);
        return () => unsubscribe();
    }, []);

    return {
        user,
        loading,
        sendSignInLink,
        completeSignOnWebPage,
        // sendEmailVerification,
        signout,
        updateEmail,
    };
}

const getAdminStatus = (email: string) => {
    const split = email.split('@');
    const domain = split[split.length - 1];
    return domain === 'virra.com';
};

const getStripeRole = async () => {
    if (clientAuth.currentUser) {
        await getIdToken(clientAuth.currentUser, true);
        const decodedToken = await getIdTokenResult(clientAuth.currentUser);
        // console.log('decodedToken');
        // console.log(decodedToken);
        // console.log('decodedToken');
        return decodedToken.claims.stripeRole;
    }
    return 'error';
};

const formatUser = async (rawUser: any): Promise<UserAuthProps> => {
    const token = await rawUser.getIdToken();
    return {
        uid: rawUser.uid,
        email: rawUser.email,
        // name: rawUser.name,
        provider: rawUser.providerData[0].providerId,
        creationTime: new Date(rawUser.metadata.creationTime).toISOString(),
        lastSignInTime: new Date(rawUser.metadata.lastSignInTime).toISOString(),
        isVerified: clientAuth.currentUser?.emailVerified || false,
        isAdmin:
            (getAdminStatus(rawUser.email) &&
                clientAuth.currentUser?.emailVerified) ||
            false,
        // stripeRole: await getStripeRole(),
        // photoUrl: user.photoURL,
        // role: getUserRole(rawUser),
        token,
    };
};
