import { useAuth } from '@/lib/auth';
import useSWR, { KeyedMutator } from 'swr';
import { User } from 'virraFirebase';

const fetcher = async (url: string, token: string, uid: string) => {
    const res = await fetch(url, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            token,
            uid,
        }),
        credentials: 'same-origin',
    });
    return res.json();
};

interface UseUserProps {
    dbUser: User;
    mutate: KeyedMutator<any>;
    loggedOut: any;
    userLoading: any;
}

export default function useUser() {
    const { user, loading } = useAuth();
    const { data, mutate, error } = useSWR(
        user ? ['/api/profile', user.token, user.uid] : null,
        fetcher,
        {
            fallbackData: user,
        }
    );

    if (!loading && !data) {
        // const loggedOut = error && error.status === 403;
        return { loggedOut: true };
    }

    const userLoading = !data && !error;

    return {
        userLoading,
        dbUser: data as User,
        mutateUser: mutate,
    };
}
