import type Stripe from 'stripe';
import { clientFirestore, firebaseApp } from './firebase';
import {
    addDoc,
    collection,
    doc,
    DocumentData,
    DocumentSnapshot,
    getDoc,
    getDocs,
    setDoc,
    onSnapshot,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import getStripe from './stripe';
import type { FirebasePriceProps } from 'types/FirebasePriceProps';
import { playlistProducts } from 'util/playlist-products';
// https://firebase.google.com/docs/reference/js

// collection: users
// document: {firebaseAuthID}
interface UserProps {
    name: string;
    email: string;
    creationTime: Date;
    lastSignIn: Date;
    totalSearches: number;
    plan: string;
    uid: string;
    stripeID: string;
}

interface PlanProps {
    title: string;
    description: string;
    price: number; // price in cents?
    searches: number;
    stripeProductId: string;
}

interface UserSearchProps {
    email: string;
    dateCreated: Date;
    dateFulfilled: Date;
    handle: string;
    plan: PlanProps;
    status:
        | {
              code: 0;
              message: 'Unfulfilled';
          }
        | {
              code: 200;
              message: 'Fulfilled';
          }
        | {
              code: 404;
              message: 'Not Found';
          }
        | {
              code: 411;
              message: 'Length required';
          };
}

export async function getUser(email: string) {
    const docRef = doc(clientFirestore, 'users', email);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
    } else {
        console.log('No such document!');
    }
}

export async function setUser<T>(uid: string, data: T) {
    const docRef = doc(clientFirestore, 'users', uid);

    try {
        await setDoc(docRef, { uid, ...data }, { merge: true });
        console.log('Document successfully written!');
    } catch (error) {
        console.error('Error writing document: ', error);
    }
}

export async function createSearch<T>(uid: string, data: T) {
    const docRef = doc(clientFirestore, 'users', uid, 'searches');

    const userSearch = {
        email: '',
        dateCreated: new Date(),
        handle: '',
        plan: {},
        status: {
            code: 0,
            message: 'Unfulfilled',
        },
    };

    try {
        await setDoc(docRef, { uid, ...data }, { merge: true });
        console.log('Document successfully written!');
    } catch (error) {
        console.error('Error writing document: ', error);
    }
}

export async function createUser<T>(uid: string, data: T) {
    const docRef = doc(clientFirestore, 'users', uid);

    try {
        await setDoc(docRef, { uid, ...data }, { merge: true });
        console.log('Document successfully written!');
    } catch (error) {
        console.error('Error writing document: ', error);
    }
}

export async function createCheckoutSession({
    uid,
    priceId,
    mode,
    metadata,
}: {
    uid: string;
    priceId: string;
    mode: 'payment' | 'subscription';
    metadata?: any;
}) {
    const collectionRef = collection(
        clientFirestore,
        'users',
        uid,
        'checkout_sessions'
    );

    console.log(priceId);

    const checkoutSessionDocRef = await addDoc(collectionRef, {
        mode: mode, // mode 'payment' for one-time payment
        price: priceId,
        allow_promotion_codes: true, // This can be removed if you don't want promo codes
        success_url: window.location.origin + '?checkout=success',
        cancel_url: window.location.origin,
        ...(metadata ? { metadata: metadata } : null),
    });
    //
    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(checkoutSessionDocRef, async (snap) => {
        // const { sessionId } = snap.data() as checkoutSessionProps;
        // const { error, url } = snap.data() as Stripe.Checkout.Session;
        const { error, url } = snap.data() as any;

        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
        }

        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
        console.log(snap.data());
        // if (sessionId) {
        //   const stripe = await getStripe();
        //   if (stripe) {
        //     stripe.redirectToCheckout({ sessionId });
        //   } else {
        //     console.log('err: no stripe');
        //   }
        // } else {
        //   console.log('err: no session id');
        // }
    });

    //

    // const docSnap = await getDoc(checkoutSessionDocRef);

    // if (docSnap.exists()) {
    //   console.log('Document data:', docSnap.data());
    // } else {
    //   console.log('No such document!');
    // }
}

export async function goToBillingPortal() {
    const functionRef = httpsCallable(
        getFunctions(firebaseApp, 'us-central1'),
        'ext-firestore-stripe-subscriptions-createPortalLink'
    );

    const { data }: { data: any } = await functionRef({
        returnUrl: `${window.location.origin}/`,
    });

    console.log(data);
    window.location.assign(data.url);
    return data;
}

interface checkoutSessionProps extends DocumentSnapshot<DocumentData> {
    sessionId: string;
}
