import LinkWrapper from './LinkWrapper';
import { adminNavigationFilter } from './Navigation';

export default function Footer() {
    return (
        <footer>
            <nav>
                <ul className="m-auto grid max-w-prose grid-cols-2 gap-4 px-4 sm:grid-cols-4">
                    {adminNavigationFilter().map((navItem) => (
                        <li key={navItem.href}>
                            <LinkWrapper
                                href={navItem.href}
                                className="block rounded-lg py-3 text-center text-gray-500 hover:bg-gray-100 hover:text-black"
                            >
                                {navItem.text}
                            </LinkWrapper>
                        </li>
                    ))}
                </ul>
            </nav>
        </footer>
    );
}
