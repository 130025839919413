import { initializeApp } from 'firebase/app';
import {
    getAuth,
    sendSignInLinkToEmail,
    signOut,
    isSignInWithEmailLink,
    signInWithEmailLink,
    getIdToken,
    getIdTokenResult,
    verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { getFirestore, onSnapshot } from 'firebase/firestore';
// import {} from 'firebase/storage'
// import 'firebase/analytics';
// import 'firebase/storage'
// https://firebase.google.com/docs/web/setup#using-module-bundlers

const firebaseConfig =
    process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
        ? {
              apiKey: 'AIzaSyDyul8eR_Gn67G2Z2WumbJJZGH19mxLMco',
              authDomain: 'project-84401.firebaseapp.com',
              projectId: 'project-84401',
              storageBucket: 'project-84401.appspot.com',
              messagingSenderId: '819341591144',
              appId: '1:819341591144:web:adb403f951758eced2f1dd',
          }
        : {
              apiKey: 'AIzaSyA2skKjF-CPVX-n_5GoV9w2j10_BQc2Esc',
              authDomain: 'virra-5470f.firebaseapp.com',
              databaseURL: 'https://virra-5470f.firebaseio.com',
              projectId: 'virra-5470f',
              storageBucket: 'virra-5470f.appspot.com',
              messagingSenderId: '57353768320',
              appId: '1:57353768320:web:bd1007760fdaf6a7d24565',
              measurementId: 'G-CDPGV331V2',
          };

const firebaseApp = initializeApp(firebaseConfig);

const clientAuth = getAuth();
const clientFirestore = getFirestore();

export {
    firebaseApp,
    onSnapshot,
    clientAuth,
    clientFirestore,
    sendSignInLinkToEmail,
    signOut,
    isSignInWithEmailLink,
    signInWithEmailLink,
    getIdToken,
    getIdTokenResult,
    verifyBeforeUpdateEmail,
};

export type FirebaseDocumentWithId<T> = T & { id: string };
