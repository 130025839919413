import Link from 'next/link';

type LinkProps = {
    href: string;
    className?: string;
    newTab?: boolean;
    children: React.ReactNode;
};

const LinkWrapper: React.FC<LinkProps> = ({
    children,
    href,
    className,
    newTab,
}) => {
    const internal = href.charAt(0) === '/';

    if (internal) {
        return (
            <Link href={href}>
                <a className={className}>{children}</a>
            </Link>
        );
    }

    if (newTab) {
        return (
            <a
                href={href}
                className={className}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }

    return (
        <a href={href} className={className}>
            {children}
        </a>
    );
};

export default LinkWrapper;
