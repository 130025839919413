import Image from 'next/image';

export default function Loading(props: { text: string }) {
    return (
        <div className="flex items-center justify-center h-screen min-h-screen pt-6">
            <Image
                className="animate-pulse"
                src="/assets/icons/virra-logo-min.svg"
                width={120}
                height={120}
                alt=""
            />
            <p className="text-gray-500">{props.text}</p>
        </div>
    );
}
