import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import Footer from './Footer';
import Navigation from './Navigation';
import { loginPaths, useAuth } from '@/lib/auth';
import Loading from './Loading';
import Link from 'next/link';
import Section from './Section';
import { UserIcon, BanknotesIcon } from '@heroicons/react/20/solid';
import Error from 'next/error';
import useUser from '@/util/use-user';

export default function Layout({
    children,
    header,
    isAdminOnly,
}: {
    children: ReactNode;
    header: string;
    isAdminOnly: boolean;
}) {
    const auth = useAuth();
    const router = useRouter();
    const isAtLoginPage = loginPaths.includes(router.pathname);
    const { dbUser } = useUser();

    // route user to login if they are not logged in
    if (!auth.loading && !auth.user && !isAtLoginPage) {
        console.log('Time to login!');
        router.replace('/login');
    }

    // show loading state while auth is checked
    if (auth.loading && !isAtLoginPage && !isAdminOnly && !dbUser) {
        return (
            <div className="py-6">
                <Link href="/">
                    <a>
                        <h1 className="text-center text-3xl font-extrabold tracking-tight text-black">
                            {header}
                        </h1>
                    </a>
                </Link>
                <Navigation />
                <Loading text={''} />
            </div>
        );
    }

    // if (!auth.user) {
    //     console.log('No user');
    //     return (
    //         <>
    //             {/* <Navigation /> */}
    //             <Loading text={''} />
    //         </>
    //     );
    // }

    if (isAtLoginPage) {
        return <>{children}</>;
    }

    // if (isAdminOnly && !auth?.user?.isAdmin) {
    //     return <Error statusCode={404} />;
    // }

    if (!dbUser) {
        return <Loading text="" />;
    }

    // show error page if user is not admin on admin page
    return (
        <div className="py-6">
            {auth.user && (
                <Section>
                    <div className="pb-6">
                        <div className="relative">
                            <Link href="/profile">
                                <a className="absolute top-0 left-0 flex items-center align-middle">
                                    <div className="block">
                                        <span className="inline-flex items-center rounded-full bg-purple-100 px-3 py-2 text-xs font-medium text-purple-800">
                                            <BanknotesIcon className="h-4 w-4" />
                                            {dbUser.credits
                                                ? dbUser.credits
                                                : 0}
                                        </span>
                                    </div>
                                </a>
                            </Link>
                            <Link href="/">
                                <a>
                                    <h1 className="text-center text-3xl font-extrabold tracking-tight text-black">
                                        {header}
                                    </h1>
                                </a>
                            </Link>
                            <Link href="/profile">
                                <a className="absolute top-0 right-0 flex items-center align-middle">
                                    <div className="hidden sm:block">
                                        <span className="inline-flex items-center rounded-full bg-purple-100 px-3 py-2 text-xs font-medium text-purple-800">
                                            {auth.user.email}
                                        </span>
                                    </div>
                                    <div className="block sm:hidden">
                                        <span className="inline-flex items-center rounded-full bg-purple-100 p-2 text-xs font-medium text-purple-800">
                                            <UserIcon className="h-5 w-5" />
                                        </span>
                                    </div>
                                </a>
                            </Link>
                        </div>
                        <Navigation />
                    </div>
                </Section>
            )}

            {isAdminOnly && !auth?.user?.isAdmin ? (
                <Error statusCode={404} />
            ) : (
                children
            )}

            {auth.user && (
                <div className="py-4">
                    <Footer />
                </div>
            )}
        </div>
    );
}
